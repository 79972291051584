<!--
// nuxt-ui/components/pages/region-matches/TableRowMatchMatches.vue
-->
<script setup lang="ts">
import type { Match } from "~/src/match/domain/models/Match";
import type { Team } from "~/src/team/domain/models/Team";
import type { DateTimeFormatOptions } from "@intlify/core-base";
import HomeTeam from "~/nuxt-ui/components/pages/region-matches/HomeTeam.vue";
import AwayTeam from "~/nuxt-ui/components/pages/region-matches/AwayTeam.vue";
import TicketIcon from "~/nuxt-ui/components/Shared/icons/TicketIcon.vue";
import nuxtLink from "#app/components/nuxt-link";
import matchUiService from "~/src/match/infrastructure/ui-services/matchUiService";
import matchService from "~/src/match/domain/services/matchService";
import { useMatches } from "~/nuxt-ui/Shared/composables/season/matches";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { isFutureDate, slugify } from "~/src/Shared/utils";
import ChevronRightIcon from "~/nuxt-ui/components/Shared/icons/ChevronRightIcon.vue";

type Props = {
  match: Match;
  awayTeam: Team | undefined;
  homeTeam: Team | undefined;
  showGroup: boolean;
  showTickets: boolean;
};

const props = defineProps<Props>();

const { locale } = useI18n();
const localePath = useLocalePath();
const route = useRoute();
const { regionParam, regionTimeZone } = useRegions();
const { matchHasPage } = matchService();
const { transformStatus } = matchUiService();
const { formatMatchTime, showDateTime, setMatchTimeZone } = useMatches();

const matchTime = formatMatchTime(
  locale.value,
  props.match.date,
  regionTimeZone,
  "stadium",
  props.match.stadiumId
);
const matchTickets = props.match?.metaInformation?.tickets_url;

const dateOptions: DateTimeFormatOptions = {
  month: "2-digit",
  day: "numeric",
  year: "2-digit",
  timeZone: setMatchTimeZone("stadium", props.match.stadiumId)?.timezone || regionTimeZone,
};

const getWinner = (match: Match): string => {
  if (match.scores.homeScore > match.scores.awayScore) {
    return "home";
  } else if (match.scores.homeScore < match.scores.awayScore) {
    return "away";
  } else {
    if (match.scores.homeScoreP > match.scores.awayScoreP) {
      return "home";
    }
    if (match.scores.homeScoreP < match.scores.awayScoreP) {
      return "away";
    }
  }
};
</script>

<template>
  <div class="match-row-container">
    <component
      :is="matchHasPage(match.seasonId) ? nuxtLink : 'div'"
      :to="
        matchHasPage(match.seasonId)
          ? localePath({
              name: 'region-matches-matchId-homeTeam-vs-awayTeam',
              params: {
                region: route.params.region,
                matchId: match.id,
                homeTeam: slugify(homeTeam?.shortName),
                awayTeam: slugify(awayTeam?.shortName),
              },
            })
          : undefined
      "
      class="absolute top-0 left-0 right-0 bottom-0"
    >
    </component>
    <!--    col 1 lg /hidden sm-->
    <div v-if="match.date" class="hidden lg:block">
      {{ new Date(match.date).toLocaleDateString(locale, dateOptions) }}
    </div>

    <!--    col 2 lg /row 1 col 1 sm-->
    <div class="flex items-center justify-start gap-2">
      <p v-if="showGroup" class="group-box lg:hidden">
        {{ match.groupName }}
      </p>
      <span class="text-xs lg:text-base lg:min-w-24">{{
        match.date && showDateTime(matchTime) ? matchTime : "-"
      }}</span>
    </div>

    <!--   hidden lg /row 1 col 2 sm-->
    <div class="flex items-center justify-end gap-2 lg:hidden">
      <p v-if="match.status" class="text-xs">{{ $t(transformStatus(match.status)) }}</p>
      <a
        v-if="!!matchTickets && isFutureDate(match.date)"
        :href="matchTickets"
        class="max-w-max text-xs flex items-center justify-center gap-1 py-1 px-2 bg-primary rounded-md text-black relative z-20"
        target="_blank"
      >
        <ticket-icon width="10"></ticket-icon>{{ $t("header.tickets") }}
      </a>
    </div>

    <!--    col3 lg/ row 2 col 1 sm-->
    <div class="col-span-2 w-full flex items-center justify-between lg:justify-center lg:col-auto">
      <div class="grid grid-cols-[1fr_auto_1fr] lg:gap-x-4 gap-x-1 w-full px-2.5 lg:pl-0 items-center">
        <home-team v-if="homeTeam" :team="homeTeam" :isWinner="getWinner(match) === 'home'" />

        <div class="flex justify-center items-baseline text-white">
          <span
            v-if="typeof match.scores.homeScoreP === 'number'"
            class="font-bold text-sm text-primary mx-1"
          >
            {{ match.scores.homeScoreP }}
          </span>
          <p class="font-bold text-lg" :class="{ 'ml-1': !match.scores.homeScoreP }">
            {{ match.scores.homeScore === null ? "-" : match.scores.homeScore }}
          </p>
          <p class="text-gray-400 text-xs lg:text-sm lg:mx-3.5 mx-1 self-center">VS</p>
          <p class="font-bold text-lg" :class="{ 'mr-1': !match.scores.awayScoreP }">
            {{ match.scores.awayScore === null ? "-" : match.scores.awayScore }}
          </p>
          <span
            v-if="typeof match.scores.awayScoreP === 'number'"
            class="font-bold text-sm text-primary mx-1"
          >
            {{ match.scores.awayScoreP }}
          </span>
        </div>

        <away-team v-if="awayTeam" :team="awayTeam" :isWinner="getWinner(match) === 'away'" />
      </div>

      <div class="lg:hidden text-secondary">
        <chevron-right-icon width="20" height="24"></chevron-right-icon>
      </div>
    </div>

    <!--    col4-->
    <div class="flex items-center justify-end gap-8">
      <div class="hidden lg:block text-right min-w-14">
        {{ match.status ? $t(transformStatus(match.status)) : "-" }}
      </div>

      <div v-if="showGroup" class="hidden lg:block min-w-14 text-right">
        <span class="group-box">
          {{ match.groupName }}
        </span>
      </div>

      <div v-if="showTickets" class="items-center justify-end hidden lg:flex min-w-14 relative z-20">
        <a
          v-if="!!matchTickets && isFutureDate(match.date)"
          :href="matchTickets"
          class="bg-primary text-black rounded-md py-2 px-3"
          target="_blank"
        >
          <ticket-icon width="20" height="20"></ticket-icon>
        </a>
        <span v-else></span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.match-row-container {
  @apply rounded-lg bg-gray-800 mb-2 items-center px-2 pt-1.5 lg:p-4 w-full grid grid-rows-[auto_1fr] auto-rows-auto grid-cols-[auto_auto] auto-cols-min;
  @apply lg:grid-rows-1 lg:grid-cols-[6rem_auto_1fr_10rem] gap-2 lg:gap-4 text-gray-400 relative;
}

.group-box {
  @apply rounded border-primary border w-fit text-primary lg:px-2 lg:py-1 px-1 text-[10px] lg:text-sm;
  @apply font-normal lg:font-medium;
}
</style>
